import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { defineCustomElements } from "@ionic/pwa-elements/loader";

import * as serviceWorker from "./serviceWorker";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/animate.css/animate.min.css";
// import '../node_modules/normalize.css/normalize.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { IonApp, IonSkeletonText } from "@ionic/react";
// import App from "./app";
const App = lazy(() => import("./app"));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window).catch();

const renderLoader = () => (
  <div className="ion-text-center">
    <IonSkeletonText
      animated={true}
      style={{ width: "80px"}}
    ></IonSkeletonText>
  </div>
);

ReactDOM.render(
  // <React.StrictMode>
  <IonApp>
    <Suspense fallback={renderLoader()}>
      <App />
    </Suspense>
  </IonApp>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
